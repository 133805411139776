import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Button,Typography, RadioGroup, Radio, FormControlLabel } from '@material-ui/core';
import WizardQuestionView from './WizardQuestionView';
// import StyledButton from './StyledButton';
import { makeRTL, isRTL } from './utils';
import { isMobile } from 'react-device-detect';
import * as Actions from "../redux/actions";

const useStyles = makeStyles({
    mainContainer:{
        margin: 'auto',
        width: isMobile ? '320px' : '600px',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch', 
        alignContent: 'stretch'
    },
    title: {
        fontFamily: 'Assistant',
        fontSize: '26px',
        fontWeight: '700',
        letterSpacing: '0px',
        color: '#35373D',
        // margin: '35px 32px'
        marginLeft: '32px',
        marginRight: '32px',
        // marginTop: '24px',
    },
    text: {
        fontFamily: 'Assistant',
        fontSize: '18px',
        fontWeight: '700',
        margin: '-4px 32px 0px 32px',
        color: '#777777'
    },
    explanation: {
        fontFamily: 'Assistant',
        fontSize: '14px',
        color: '#777777',
        margin: '0px 32px',
        fontStyle: 'italic'
    },
    label: {
        opacity: '0.77',
        fontFamily: 'Assistant',
        fontSize: '11px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: '0.22px',
        textAlign: 'center',
        color: '#97a1b0',        
    },
    indicator: {
        backgroundColor: "#1274e4"
    },
    tab: {
        backgroundColor: 'white',
        boxShadow: 'none',
        '&.MuiTab-root': {
            width: '50%'
        },
        '&.Mui-selected > span > span': {
            fontFamily: 'Assistant',
            fontSize: '11px',
            fontWeight: '700',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 'normal',
            letterSpacing: '0.12px',
            textAlign: 'center',
            color: '#162250'            
        }        
    },
    formGroup: {
        flexDirection: 'row'
    },
    radioGroup: {
        marginLeft: '24px',
        marginRight: '24px',
    },
    layout: {
        display: 'flex',
        flexDirection: 'row'
    },
    repeatLeftSide: {
        overflowY: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        marginRight: '12px',
        marginLeft: '12px',
        width: '25%'
    },
    contentRightSide: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch', 
        alignContent: 'stretch',
        flexGrow: 1,
        overflowY: 'hidden'
    },
    selected: {
        backgroundColor: '#1274e4 !important',
        color: 'white !important'
    }
});

const RepeatStyledButton = withStyles(theme => ({
    root: {
        width: '128 px',
        height: '42px',
        fontFamily: 'Assistant',
        fontSize: '16px',
        fontWeight: '600',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        borderRadius: '21px',
        color: 'black',
        backgroundColor: '#1274E40C',
        margin: '12px',        
        '&:hover': {
            backgroundColor: '#1060BB'
      },
    },
}))(Button);

export default function WizardMultipleQuestionView({question, onAnswerChange, contactsValue}) {
    const classes = useStyles();
    const repeatType = question.repeat_type;
    const SEPARATOR = '|';
    const isRepeating = repeatType && repeatType !== '' && repeatType !== 'do_no_repeat';
    const children =  question.children;
    const questionnaire = useSelector(state => state.questionnaire);
    const partyQuestion = question.children && question.children.filter(q => q.read_only)[0];

    const originalAnswer = questionnaire[question.id];
    const rootAnswer = originalAnswer !== undefined ? originalAnswer : isRepeating ?
        [{ question_id: question.id, children:[] }]:
        { question_id: question.id, children: [] };
    const [repeatSelectedIndex, setRepeatSelectedIndex] = useState(0);

    const childAnswers = {};
    if (rootAnswer.children) { //this is multi and not repeating
        for (let i = 0; i < rootAnswer.children.length; ++i) {
            childAnswers[rootAnswer.children[i].question_id] = rootAnswer.children[i];
        }
    } else if (isRepeating) {// this is repeating
        // TODO i add this condition becouse it producese error. I dont know will it affect document generation, so we need to resourched
        if( rootAnswer[repeatSelectedIndex] &&  rootAnswer[repeatSelectedIndex].children){
            const _rootAnswerChildren = rootAnswer[repeatSelectedIndex].children;
            for (let i = 0; i < _rootAnswerChildren.length; ++i) {
                childAnswers[_rootAnswerChildren[i].question_id] = _rootAnswerChildren[i];
            }
        }
    }
    const partyValue = partyQuestion && childAnswers[partyQuestion.id] ? childAnswers[partyQuestion.id].value : undefined;
    const RTL = isRTL(question.title);
    const linkInText = RTL ? 'לחצו כאן' : 'click here';
    const dispatch = useDispatch();

    const text = question.text;
    let newText = '';

    if (text && text.includes('http') ) {
        let textArr = text.split(' ');
        for (let i = 0; i < textArr.length; ++i) {
            if(textArr[i].includes('http')){
                const link = ` <a target='_blank' href = '${textArr[i]}'>${linkInText}</a> `;
                newText += `${link} `;
            } else {
                newText += `${textArr[i]} `;
            }
        }
    } else {
        newText = question.text;
    }

    const partFormAddContactData = () => {

        const getContactValueFromArray = (index, contactValue)=>{
            const arrContactValue = contactValue?.split(SEPARATOR);
            if(arrContactValue){
                return arrContactValue[index] || contactValue;
            } else {
                return contactValue;
            }

        }


        const {children: questionChildrenForPartyForm} = question;

            let arrQuestionChildrenForPartyForm=[];
            let childrenArr=[];

            if(rootAnswer.children) {
                childrenArr = [...rootAnswer.children];
            } else {
                childrenArr = [...rootAnswer];
            }

            const lengthFromContactArr = []
            for (let i = 0; i < questionChildrenForPartyForm.length; ++i) {
                let usedContactField = questionChildrenForPartyForm[i].contact_field;
                if (usedContactField !== undefined ) {
                    lengthFromContactArr.push(
                        contactsValue[questionChildrenForPartyForm[i].contact_field]?.split(SEPARATOR).filter(segment => segment.trim()).length
                    );
                }
            }

            let minLengthFromContactArr = 0;
            if(lengthFromContactArr.length){
                minLengthFromContactArr = lengthFromContactArr.reduce((a, b) => Math.min(a, b))
            }


            if(childrenArr.length < minLengthFromContactArr){
                for (let i = childrenArr.length; i < minLengthFromContactArr; ++i) {
                    childrenArr.push(childrenArr[0])
                }
            }

            const newChildrenArr = [];

            for (let i = 0; i < childrenArr.length; ++i) {

                const {children} = childrenArr[i];

                const newChildrenArrInner = [];

                if(children && children.length){
                    for (let j = 0; j < children.length; ++j) {

                        const getValue = getContactValueFromArray(i, children[j].value || contactsValue[questionChildrenForPartyForm[j].contact_field] )

                        if (getValue) {
                            newChildrenArrInner.push({...children[j], value:getValue})
                        } else {
                            newChildrenArrInner.push({...children[j], value:''})
                        }
                    }
                    newChildrenArr.push({...childrenArr[i], children: newChildrenArrInner})
                } else {
                    if (i===0){
                        newChildrenArr.push(childrenArr[i] )
                    } else {
                            newChildrenArr.push(childrenArr[i] )
                    }



                }



            }

            if(newChildrenArr.length){
                childrenArr = newChildrenArr;
            }

            for (let i = 0; i < questionChildrenForPartyForm.length; ++i) {

                let usedContactField = questionChildrenForPartyForm[i].contact_field;

                if (usedContactField !== undefined) {
                    if (i === 0 && partyQuestion !== undefined) {
                        let answer = {
                            question_id: questionChildrenForPartyForm[i].id,
                            value: partyValue || ''
                        }
                        arrQuestionChildrenForPartyForm.push(answer);
                    } else if (questionChildrenForPartyForm[i]?.conditions[0]?.value === partyValue) {

                        let answerWithoutContactData = childrenArr.find(f => f.question_id === questionChildrenForPartyForm[i].id)?.value;

                        if (answerWithoutContactData === undefined && childrenArr.children === undefined) {
                            if (childrenArr[repeatSelectedIndex].children  !== undefined ){
                                answerWithoutContactData = childrenArr[repeatSelectedIndex].children.find(f => f.question_id === questionChildrenForPartyForm[i].id)?.value || '';
                            } else {
                                answerWithoutContactData = childrenArr.find(f => f.question_id === questionChildrenForPartyForm[i].id)?.value || '';
                            }
                        }
                        let answer = {
                            question_id: questionChildrenForPartyForm[i].id,
                            value: answerWithoutContactData || contactsValue[questionChildrenForPartyForm[i].contact_field],
                        }

                        console.log('------answer123----', answer)

                        arrQuestionChildrenForPartyForm.push(answer);
                    }
                } else {
                    let answer = {}
                    if( childrenArr[repeatSelectedIndex].children !== undefined ) {
                        answer = {
                            question_id: questionChildrenForPartyForm[i].id,
                            value: childrenArr[repeatSelectedIndex].children.find(f => f.question_id === questionChildrenForPartyForm[i].id)?.value || ''
                        }
                    } else {
                        answer = {
                            question_id: questionChildrenForPartyForm[i].id,
                            value: childrenArr.find(f => f.question_id === questionChildrenForPartyForm[i].id)?.value || ''
                        }
                    }
                    console.log('------answer1234----', answer)
                    arrQuestionChildrenForPartyForm.push(answer);
                }

            }
            if(rootAnswer.children) {
                childrenArr = {question_id: rootAnswer.question_id, children: arrQuestionChildrenForPartyForm}
            } else {
                childrenArr[repeatSelectedIndex] = {question_id: rootAnswer[repeatSelectedIndex].question_id, children: arrQuestionChildrenForPartyForm}
            }

            //console.log('childrenArr', childrenArr)
            dispatch(Actions.saveAnswer(childrenArr));

    }

    useEffect((child={}) => {
        if(contactsValue || partyValue) {
            if (Object.keys(contactsValue).length && partyQuestion === undefined && rootAnswer?.children && question?.children) {
                const cc = [...rootAnswer.children];
                for (let i = 0; i < question.children.length; ++i) {

                    let contactFieldName = question.children[i].contact_field;
                    if (contactsValue[contactFieldName]){
                        cc[i] = {
                            ...cc[i], value : contactsValue[contactFieldName] || '', question_id:question.children[i].id
                        }
                    }
                }
                const toUse = {question_id: question.id, ...rootAnswer, children: rootAnswer?.children || cc };

                dispatch(Actions.saveAnswer(toUse));
            } else if (Object.keys(contactsValue).length && partyQuestion === undefined && !rootAnswer.children){
                partFormAddContactData();
            }

            if (Object.keys(contactsValue).length && partyQuestion !== undefined && partyValue ){
                partFormAddContactData();
            }
        }

    }, [contactsValue, partyValue, question]);

    const onChildAnswerChange = async (child) => {
        const answer =  isRepeating ? rootAnswer[repeatSelectedIndex] : //if repeating we copy the array as is and change the changed index 
            questionnaire[question.id];
        console.log('when starting:', questionnaire[question.id]);

        let found = -1;
        for (let i = 0; i < answer.children.length; ++i) {
            if (child.question_id.toString() === answer.children[i].question_id.toString()) {
                found = i;
                break;
            }
        }
        let cc = [...answer.children];            
        if (found < 0) {
            cc.push(child);
        } else {
            cc[found] = child;
        }
        
        if (isRepeating) {
            const toUse = [...rootAnswer];
            toUse[repeatSelectedIndex] = {question_id: question.id, children: cc};
            onAnswerChange(toUse);
        } else { 
            console.log({answer});
            // const toUse = Object.assign( {}, answer, {children: cc});
            // console.log('TOUSE', {toUse, answer}, questionnaire[question.id]);
            const toUse = {question_id: question.id, ...answer, children: cc};
            console.log('FFINSIHIN:', toUse, answer, questionnaire[question.id]);
            onAnswerChange(toUse);
        }
    };

    let header = <div>
        <Typography className={classes.title}>{question.title}</Typography>
        {newText && <Typography className={classes.text}> <p dangerouslySetInnerHTML={{ __html:newText }}></p></Typography>}
    </div>;

    const addAnother = async () => {
        let toUse = [...rootAnswer];
        toUse.push({question_id: question.id, children: rootAnswer[0].children.map(c => {
                if(Object.keys(contactsValue).length){
                    let findContactData = question.children.find(contactField => contactField.id === c.question_id)?.contact_field;
                    if (findContactData !== undefined && contactsValue[findContactData] !== undefined) {
                        return Object.assign({}, c, {
                            value: contactsValue[findContactData].includes('|') ? '' : contactsValue[findContactData] || '',
                            other_value: ''
                        });
                    } else {
                        return Object.assign({}, c, {value: '', other_value: ''});
                    }
                } else {
                    return Object.assign({}, c, {value: '', other_value: ''});
                }
        })});
        onAnswerChange(toUse);
        await setRepeatSelectedIndex(rootAnswer.length);
    };

    let radioGroup =(<RadioGroup value={partyValue} style={{flexDirection: 'row', marginLeft: '24px', marginRight: '24px'}} onChange={e=>{
        onChildAnswerChange({ question_id: partyQuestion.id, value: e.target.value });
    }} className={classes.radio}>
        <FormControlLabel key={0} value="0" control={<Radio />} label={RTL ? 'פרטי' : 'INDIVIDUAL'} />
        <FormControlLabel key={1} value="1" control={<Radio />} label={RTL ? 'חברה' : 'CORPORATION'} />
    </RadioGroup>);
    const addAnotherLabel = RTL ? 'הוספה' :'Add Another'

    let repeatButtons = [];
    if (isRepeating) {
        for (let i = 0; i < rootAnswer.length; i++) {
            repeatButtons.push(<RepeatStyledButton className={i === repeatSelectedIndex ? classes.selected : ''}
                key={i} onClick={async ()=>{await setRepeatSelectedIndex(i)}}>{`${question.title} ${i + 1}`}</RepeatStyledButton>);
        }
        if (repeatButtons.length === 0) 
            repeatButtons.push(<RepeatStyledButton key={'0'} onClick={async ()=>{await setRepeatSelectedIndex(0)}}>{`${question.title} 1`}</RepeatStyledButton>);
        if (repeatType === 'let_user_add') 
            repeatButtons.push(<RepeatStyledButton key={rootAnswer.length + 1} onClick={async()=>{ await addAnother()}}>{addAnotherLabel}</RepeatStyledButton>);
    }

    if (RTL){
        header = makeRTL(header);
        radioGroup = makeRTL(radioGroup);
    }

    console.log('----question----', question)
    console.log('---rootAnswer---', rootAnswer)

    return <div className={classes.mainContainer}>
        {header}
        <div className={classes.layout}>
            {isRepeating && <div className={classes.repeatLeftSide}>
                {repeatButtons}
            </div>}
            <div className={classes.contentRightSide}>
                {partyQuestion && radioGroup}
                {partyQuestion && partyValue && children.map(q => !q.read_only && q.conditions &&
                    q.conditions[0].question_id === partyQuestion.id && q.conditions[0].value === partyValue.toString() && <WizardQuestionView
                        key={q.id} question={q}
                        onAnswerChange={onChildAnswerChange}
                        answer={childAnswers[q.id] || { question_id: q.id, value: questionnaire.defaults[q.id] }}
                        parentAnswer={rootAnswer}
                        contactsValue={contactsValue[q.contact_field] || ''}
                        childAnswers = {!!childAnswers}
                        partyQuestion = {partyQuestion}
                    />
                )}
                {!partyQuestion && children.map(q => <WizardQuestionView
                        key={q.id} question={q}
                        onAnswerChange={onChildAnswerChange}
                        answer={childAnswers[q.id] || { question_id: q.id, value: questionnaire.defaults[q.id] }}
                        parentAnswer={rootAnswer}
                        contactsValue={contactsValue[q.contact_field] || ''}
                        childAnswers = {!!childAnswers}
                    />
                )}
            </div>
        </div>
    </div>;
}